import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation, EffectFade } from "swiper";
import { Col, Container, Row } from "react-bootstrap";

SwiperCore.use([Autoplay, Navigation, EffectFade]);
const SliderTop = ({ data }) => {
  const mainSlideOptions = {
    slidesPerView: 1,
    loop: false,
    effect: "fade",
  };
  return (
    <section className="main-slider slider-subpages slider-subpages-manual">
      <Swiper {...mainSlideOptions}>
        <SwiperSlide>
          <div
            className="image-layer"
            style={{
              backgroundImage: `url('${
                data.selectedGlobalImage
                  ? `${process.env.GATSBY_API_BASE_URL}/${data.selectedGlobalImage.path}`
                  : ""
              }')`,
            }}
          />
          <Container className="w-auto animated fadeInUp">
            <Row>
              <Col lg={12} className="text-center">
                <h3 className="main-slider__title">{data.name}</h3>
                <span className="slider-subpages-breadcrumb">
                  {data.breadcrumb}
                </span>
              </Col>
            </Row>
          </Container>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default SliderTop;
