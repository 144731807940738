import React, { useState, useEffect } from "react";
import Layout from "@/components/layout";
import Footer from "@/components/footer";
import HeaderOneTop from "@/components/header-one-top";
import HeaderOne from "@/components/header-one";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import SliderTop from "@/components/subpages/slider-top";
import Content from "@/components/subpages/content";
import api from "@/axios";
import { navigate } from "gatsby";

const Subpages = ({ params }) => {
  const [apiData, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await api.get(`/pages/o/get/${params.id}`);
        if (
          response.data &&
          response.data.status &&
          response.data.status == "error"
        ) {
          navigate("/", { replace: true });
        }
        setData(response.data);
        // setError(null);
      } catch (err) {
        // setError(err.message);
        navigate("/", { replace: true });
        setData(null);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, [params]);

  return (
    <MenuContextProvider>
      <SearchContextProvider>
        {loading && <span className="loader"></span>}
        {!loading && apiData && (
          <Layout PageTitle={apiData.name}>
            <HeaderOneTop />
            <HeaderOne />
            <SliderTop data={apiData} />
            <Content data={apiData} />
            <Footer />
          </Layout>
        )}
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default Subpages;
