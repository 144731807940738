import "@/css/timeline.css";
import React, { useEffect } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import "swiper/css/bundle";
import Moment from "react-moment";
import "moment/locale/pl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

const CompetitionsTable = ({ data }) => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      require("@/components/subpages/timeline.js");
    }
  }, [data]);
  return (
    <section className="commonSection pt-5">
      <Container className="container-single-post">
        <Row>
          <Col>
            <div
              className="single-news-content"
              dangerouslySetInnerHTML={{ __html: data.content }}
            ></div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CompetitionsTable;
